import { render, staticRenderFns } from "./RouteFromWork.vue?vue&type=template&id=3f630feb&scoped=true&"
import script from "./RouteFromWork.vue?vue&type=script&lang=ts&"
export * from "./RouteFromWork.vue?vue&type=script&lang=ts&"
import style0 from "./RouteFromWork.vue?vue&type=style&index=0&id=3f630feb&lang=scss&scoped=true&"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "3f630feb",
  null
  
)

export default component.exports

/* vuetify-loader */
import installComponents from "!../../../node_modules/vuetify-loader/lib/runtime/installComponents.js"
import { VAlert } from 'vuetify/lib/components/VAlert';
import { VApp } from 'vuetify/lib/components/VApp';
import { VAppBar } from 'vuetify/lib/components/VAppBar';
import { VAvatar } from 'vuetify/lib/components/VAvatar';
import { VBadge } from 'vuetify/lib/components/VBadge';
import { VBtn } from 'vuetify/lib/components/VBtn';
import { VCol } from 'vuetify/lib/components/VGrid';
import { VContainer } from 'vuetify/lib/components/VGrid';
import { VIcon } from 'vuetify/lib/components/VIcon';
import { VImg } from 'vuetify/lib/components/VImg';
import { VMain } from 'vuetify/lib/components/VMain';
import { VRow } from 'vuetify/lib/components/VGrid';
import { VTimeline } from 'vuetify/lib/components/VTimeline';
import { VTimelineItem } from 'vuetify/lib/components/VTimeline';
import { VToolbarItems } from 'vuetify/lib/components/VToolbar';
import { VToolbarTitle } from 'vuetify/lib/components/VToolbar';
import { VTooltip } from 'vuetify/lib/components/VTooltip';
installComponents(component, {VAlert,VApp,VAppBar,VAvatar,VBadge,VBtn,VCol,VContainer,VIcon,VImg,VMain,VRow,VTimeline,VTimelineItem,VToolbarItems,VToolbarTitle,VTooltip})
