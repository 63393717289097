
































































































































































































import Vue from 'vue';
import { mapGetters, mapActions } from 'vuex';
import { DateTime } from 'luxon';
import firebase from '@/firebase';
import alphabet from '@/utils/alphabet';
import weekdayMap from '@/utils/weekdayMap';
import { routerNames } from '@/types/routerNames';
// import RouteMap from '@/components/employee/RouteMap.vue';
import RouteNavitimeMap from '@/components/employee/RouteNavitimeMap.vue';
import {
  GETTING_ON_AND_OFF_TIME,
  MAP_DIR_URL,
  MAP_SEARCH_URL,
} from '@/const/route';

export default Vue.extend({
  components: {
    RouteNavitimeMap,
  },
  data: () => ({
    employeeId: (firebase.auth().currentUser || {}).uid,
    server: {
      finishTimes: {} as any,
      geoPoint: { latitude: 0, longitude: 0 },
      workplaceGeoPoint: { latitude: 0, longitude: 0 },
    },
    clients: [] as any,
    expectedArrivalTimes: [] as Array<DateTime>,
    myRouteIndex: 0,
    departureTime: new Date(),
    routerNames: routerNames,
    weekdayMap: weekdayMap,
    alphabet: alphabet,
    mapDirUrl: MAP_DIR_URL,
    mapSeachUrl: MAP_SEARCH_URL,
  }),
  computed: {
    ...mapGetters('EmployeesCpFromWorkOutputModule', [
      'findFeasiblePathFromWork',
      'findFeasiblePathFromWorkByServer',
      'findFeasiblePathFromWorkByClient',
    ]),
    ...mapGetters('EmployeesEmployeeModule', ['employee', 'findEmployeeById']),
  },
  methods: {
    ...mapActions('EmployeesCpFromWorkOutputModule', ['getCpFromWorkOutput']),
    ...mapActions('EmployeesEmployeeModule', ['listEmployees', 'getEmployee']),
    initRoute() {
      let feasiblePath: any = {};
      if (this.employee.type === 'server') {
        feasiblePath = this.findFeasiblePathFromWorkByServer(
          this.$route.query.weekday,
          this.employeeId
        );
      } else if (this.employee.type === 'client') {
        feasiblePath = this.findFeasiblePathFromWorkByClient(
          this.$route.query.weekday,
          this.employeeId
        );
      }
      this.server = this.findEmployeeById(feasiblePath.server);
      feasiblePath.clients.forEach((client: string) => {
        this.clients.push(this.findEmployeeById(client));
      });

      this.setMyRouteIndex(feasiblePath);
    },
    setMyRouteIndex(feasiblePath: any) {
      if (this.employee.type === 'server') {
        this.myRouteIndex = feasiblePath.clients.length + 1;
      } else if (this.employee.type === 'client') {
        this.myRouteIndex = feasiblePath.clients.indexOf(this.employeeId) + 1;
      }
    },
    setExpectedArrivalTime(duration: number) {
      this.expectedArrivalTimes.push(
        this.expectedArrivalTimes[this.expectedArrivalTimes.length - 1].plus({
          minutes: Math.round((duration + GETTING_ON_AND_OFF_TIME) / 60),
        })
      );
    },
    setDepartureTime() {
      const now = new Date();
      const temp = new Date(
        '2021/04/01 ' +
          this.server.finishTimes[this.$route.query.weekday as any]
      );
      this.departureTime.setHours(temp.getHours());
      this.departureTime.setMinutes(temp.getMinutes());
      if (this.departureTime < now) {
        this.departureTime = now;
      }
    },
    initExpectedArrivalTime(result: any) {
      // 運転者の到着時間
      const serverLeaveTime = DateTime.fromJSDate(
        new Date(
          '2021/04/01 ' +
            this.server.finishTimes[this.$route.query.weekday as any]
        )
      );
      this.expectedArrivalTimes.push(serverLeaveTime);
      // 同乗者の到着時間
      result.routes[0].legs.forEach((leg: any) => {
        this.setExpectedArrivalTime(leg.duration.value);
      });
    },
    back() {
      this.$router.push({
        name: routerNames.schedule,
      });
    },
  },
  created() {
    if (!this.$route.query.weekday) {
      this.back();
    }
    this.getEmployee({
      id: this.employeeId,
    }).then((employee) => {
      this.listEmployees({
        organizationRef: employee.organizationRef,
      }).then(() => {
        this.getCpFromWorkOutput({
          id: employee.organizationRef.id,
        }).then(() => {
          if (!this.findFeasiblePathFromWork(this.$route.query.weekday)) {
            this.back();
          }
          this.initRoute();
          this.setDepartureTime();
        });
      });
    });
  },
});
