var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('v-app',[_c('v-app-bar',{attrs:{"app":"","clipped-right":"","color":"white"}},[_c('v-btn',{attrs:{"icon":""},on:{"click":_vm.back}},[_c('v-img',{staticClass:"ma-4",attrs:{"width":"48","src":require('../../assets/logo.svg')}})],1),_c('v-toolbar-title',[_c('strong',[_vm._v(_vm._s(_vm.$route.query.date)+"（"+_vm._s(_vm.weekdayMap[_vm.$route.query.weekday])+"）退勤ルート")])]),_c('v-toolbar-items')],1),_c('v-main',[_c('v-container',[(_vm.expectedArrivalTimes[_vm.myRouteIndex])?_c('v-alert',{attrs:{"dense":"","text":"","type":"info"}},[_vm._v(" 出発は "),(_vm.employee.type === 'server')?_c('b',[_vm._v(" "+_vm._s(_vm.server.finishTimes[_vm.$route.query.weekday])+" ")]):_vm._e(),(_vm.employee.type === 'client')?_c('b',[_vm._v(" "+_vm._s(_vm.clients[_vm.myRouteIndex - 1].finishTimes[_vm.$route.query.weekday])+" ")]):_vm._e(),_vm._v("、到着予定は "),_c('b',[_vm._v(" "+_vm._s(_vm.expectedArrivalTimes[_vm.myRouteIndex].toFormat('H:mm'))+" ")]),_vm._v(" です。 ")]):_vm._e(),_c('RouteNavitimeMap',{staticClass:"route-from-work-map",attrs:{"type":_vm.routerNames.routeFromWork,"server":_vm.server,"clients":_vm.clients,"afterDirectionRouted":_vm.initExpectedArrivalTime,"departureTime":_vm.departureTime}})],1),_c('div',{staticClass:"font-weight-bold ml-8 mb-2"},[_c('v-badge',{attrs:{"avatar":"","color":"error","bordered":"","overlap":""},scopedSlots:_vm._u([{key:"badge",fn:function(){return [_c('v-avatar',[_vm._v(_vm._s(_vm.alphabet[1]))])]},proxy:true}])},[_c('v-btn',{attrs:{"icon":"","href":_vm.mapSeachUrl +
              _vm.server.workplaceGeoPoint.latitude +
              ',' +
              _vm.server.workplaceGeoPoint.longitude,"target":"_blank"}},[_c('v-icon',[_vm._v("mdi-office-building")])],1)],1)],1),_c('v-timeline',{attrs:{"align-top":"","dense":""}},[_vm._l((_vm.clients),function(client,i){return _c('v-timeline-item',{key:i,scopedSlots:_vm._u([{key:"icon",fn:function(){return [_c('v-badge',{attrs:{"avatar":"","color":"error","bordered":"","overlap":""},scopedSlots:_vm._u([{key:"badge",fn:function(){return [_c('v-avatar',[_vm._v(_vm._s(_vm.alphabet[i + 2]))])]},proxy:true}],null,true)},[_c('v-avatar',{attrs:{"size":"42"}},[_c('strong'),_c('v-img',{attrs:{"src":client.photoURL}})],1)],1)]},proxy:true}],null,true)},[_c('v-row',{attrs:{"dense":""}},[_c('v-col',{attrs:{"cols":"2"}},[(_vm.expectedArrivalTimes.length > 0)?_c('strong',[_vm._v(_vm._s(_vm.expectedArrivalTimes[i + 1].toFormat('H:mm')))]):_vm._e()]),_c('v-col',[_c('strong',[_vm._v(_vm._s(client.displayName))]),_c('br'),_c('v-tooltip',{attrs:{"top":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
              var on = ref.on;
              var attrs = ref.attrs;
return [_c('v-btn',_vm._g(_vm._b({attrs:{"icon":"","href":_vm.mapDirUrl +
                      '&origin=Current+Location&destination=' +
                      client.geoPoint.latitude +
                      ',' +
                      client.geoPoint.longitude +
                      '&travelmode=driving',"target":"_blank"}},'v-btn',attrs,false),on),[_c('v-icon',[_vm._v("mdi-map")])],1)]}}],null,true)},[_c('span',[_vm._v("到着地点")])]),_c('v-btn',{attrs:{"icon":"","href":'tel:' + client.phoneNumber}},[_c('v-icon',[_vm._v("mdi-phone")])],1),_c('v-btn',{attrs:{"icon":"","href":'sms:' + client.phoneNumber}},[_c('v-icon',[_vm._v("mdi-chat")])],1),_c('v-btn',{attrs:{"icon":"","href":'mailto:' + client.email}},[_c('v-icon',[_vm._v("mdi-email")])],1),_c('div',{staticClass:"caption"},[_vm._v(" "+_vm._s(client.location)),_c('br'),_vm._v(" 退勤 "+_vm._s(client.finishTimes[_vm.$route.query.weekday])+" ")])],1)],1)],1)}),_c('v-timeline-item',{scopedSlots:_vm._u([{key:"icon",fn:function(){return [_c('v-badge',{attrs:{"avatar":"","color":"error","bordered":"","overlap":""},scopedSlots:_vm._u([{key:"badge",fn:function(){return [_c('v-avatar',[_vm._v(_vm._s(_vm.alphabet[_vm.clients.length + 2]))])]},proxy:true}])},[_c('v-avatar',{attrs:{"size":"42"}},[_c('strong'),_c('v-img',{attrs:{"src":_vm.server.photoURL}})],1)],1)]},proxy:true}])},[_c('v-row',{attrs:{"dense":""}},[_c('v-col',{attrs:{"cols":"2"}},[(_vm.expectedArrivalTimes.length > 0)?_c('strong',[_vm._v(_vm._s(_vm.expectedArrivalTimes[_vm.expectedArrivalTimes.length - 1].toFormat( 'H:mm' )))]):_vm._e()]),_c('v-col',[_c('strong',[_vm._v(_vm._s(_vm.server.displayName)+" "),_c('small',[_vm._v("（運転者）")])]),_c('br'),_c('v-tooltip',{attrs:{"top":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
                      var on = ref.on;
                      var attrs = ref.attrs;
return [_c('v-btn',_vm._g(_vm._b({attrs:{"icon":"","href":_vm.mapSeachUrl +
                      _vm.server.geoPoint.latitude +
                      ',' +
                      _vm.server.geoPoint.longitude,"target":"_blank"}},'v-btn',attrs,false),on),[_c('v-icon',[_vm._v("mdi-map")])],1)]}}])},[_c('span',[_vm._v("到着地点")])]),_c('v-btn',{attrs:{"icon":"","href":'tel:' + _vm.server.phoneNumber}},[_c('v-icon',[_vm._v("mdi-phone")])],1),_c('v-btn',{attrs:{"icon":"","href":'sms:' + _vm.server.phoneNumber}},[_c('v-icon',[_vm._v("mdi-chat")])],1),_c('v-btn',{attrs:{"icon":"","href":'mailto:' + _vm.server.email}},[_c('v-icon',[_vm._v("mdi-email")])],1),_c('div',{staticClass:"caption"},[_vm._v(" 退勤 "+_vm._s(_vm.server.finishTimes[_vm.$route.query.weekday])+" ")])],1)],1)],1)],2)],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }